<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("users", ["logout"])
  },
  created() {
    this.logout();
    this.$router.push({ name: "login" });
  }
};
</script>

<style lang="scss"></style>
